<template>
	<div id="user-master">
		<div class="screen-title">ユーザーマスタ一覧</div>
		<b-container fluid>
			<b-row class="mx-0 mb-4 d-flex row-gap-20">
				<card class="w-100 my-0">
					<b-row class="mx-2 mt-3 d-flex col-gap-70">
						<div class="col-md col-xs-12 px-0">
							<b-row class="mx-0 d-flex pb-27 col-gap-30">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										alternative
										:placeholder="label.registername"
										v-model="model.registername"
										:label="label.registername"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
								<div class="col-md px-0">
									<base-input
										alternative
										:placeholder="label.companyname"
										v-model="model.companyname"
										:label="label.companyname"
										:labelClasses="'custom-form-input-label'"
										:inputClasses="'custom-input-class'"
									>
									</base-input>
								</div>
							</b-row>
						</div>
						<b-col class="px-0">
							<b-row class="mx-0 d-flex">
								<div class="col-md col-xs-12 px-0 res_margin_bottom">
									<base-input
										:labelClasses="'custom-form-input-label'"
										:label="label.type"
									>
										<b-form-radio
											class="custom-control-inline custom__mr-15"
											v-model="model.type"
											name="type-radios"
											value="法人"
											>法人</b-form-radio
										>
										<b-form-radio
											class="custom-control-inline custom__mr-15"
											v-model="model.type"
											name="type-radios"
											value="個人"
											>個人</b-form-radio
										>
									</base-input>
								</div>
								<b-col class="px-0">
									<base-input
										:labelClasses="'custom-form-input-label'"
										:label="label.authority"
									>
										<b-form-radio
											class="custom-control-inline custom__mr-15"
											v-model="model.authority"
											name="auth-radios"
											value="管理者"
											>管理者</b-form-radio
										>
										<b-form-radio
											class="custom-control-inline custom__mr-15"
											v-model="model.authority"
											name="auth-radios"
											value="クリエイター"
											>クリエイター</b-form-radio
										>
									</base-input>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</card>
				<b-row
					class="mx-0 w-100 mt-2 d-flex pb-27 flex-row col-gap-30 justify-content-center align-items-center"
				>
					<router-link
						:to="{
							path: 'userregister',
						}"
						><button class="customButton customButton__submit">登録</button>
					</router-link>

					<button
						class="customButton customButton__search"
						type="primary"
						@click="onSearch"
					>
						検索
					</button>
				</b-row>
				<card class="my-0 w-100">
					<b-row class="mx-4 d-flex align-items-center col-gap-20">
						<div class="elm-title">表示件数</div>
						<el-select
							class="w-147px"
							v-model="pagination.perPage"
							@change="onChangePagitionPerPage"
						>
							<el-option
								v-for="item in pagination.perPageOptions"
								:key="item"
								:label="item"
								:value="item"
							>
							</el-option>
						</el-select>
					</b-row>
					<b-row class="mx-0 px-4 my-3">
						<el-table
							:data="queriedData"
							row-key="id"
							@sort-change="sortChange"
							v-touch:swipe="ScrollTable"
						>
							<el-table-column
								v-for="col in registerNameCols"
								:key="col.label"
								v-bind="col"
							>
								<template v-if="true" v-slot:default="table">
									<router-link
										:to="{
											path: 'useredit',
											query: {
												userid: table.row.id,
											},
										}"
									>
										{{ table.row.registername }}
									</router-link>
								</template>
							</el-table-column>
							<el-table-column
								v-for="column in tableColumns"
								:key="column.label"
								v-bind="column"
								labelClassName="table-header"
							>
							</el-table-column>
						</el-table>
						<img
							src="@/assets/Icon/ScrollHintldpi.svg"
							class="scoll_hint_img"
							v-show="!isScrolling"
							:class="{ not_display: !isMobile }"
						/>
					</b-row>

					<div class="w-100 d-flex justify-content-end justify-content-sm-between">
						<base-pagination
							class="w-100 d-flex justify-content-end"
							:current="pagination.currentPage"
							:per-page="pagination.perPage"
							:total="total"
							@change="paginationChanged($event)"
						>
						</base-pagination>
					</div>
				</card>
			</b-row>
		</b-container>
	</div>
</template>
<script>
import { Select, Option } from 'element-ui';
import { Table, TableColumn } from 'element-ui';
import { BasePagination } from '@/components';
import clientPaginationMixin from '../../mixin/clientPaginationMixin';

export default {
	name: 'UserMaster',
	mixins: [clientPaginationMixin],
	components: {
		BasePagination,
		[Select.name]: Select,
		[Option.name]: Option,
		[Table.name]: Table,
		[TableColumn.name]: TableColumn,
	},
	data() {
		return {
			isMobile: false,
			isScrolling: false,
			model: {
				registername: '',
				companyname: '',
				authority: '',
				type: '',
			},
			label: {
				registername: '名前',
				companyname: '会社名',
				authority: '権限',
				type: '種別',
			},
			tableColumns: [
				{
					prop: 'companyname',
					label: '会社名',
					minWidth: 180,
					sortable: true,
				},
				{
					prop: 'type',
					label: '種別',
					minWidth: 150,
					sortable: true,
				},
				{
					prop: 'authority',
					label: '権限',
					minWidth: 150,
					sortable: true,
				},
			],
			registerNameCols: [
				{
					prop: 'registername',
					label: 'お名前',
					minWidth: 180,
					sortable: true,
				},
			],
			tableData: [],
			tableName: 'user-table',
			originalTableData: [],
		};
	},
	methods: {
		onChangePagitionPerPage() {
			this.pagination.currentPage = 1;
		},
		checkMobile() {
			let check = false;
			(function (a) {
				if (
					/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
						a
					) ||
					/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
						a.substr(0, 4)
					)
				)
					check = true;
			})(navigator.userAgent || navigator.vendor || window.opera);
			console.log(check);
			return check;
		},
		ScrollTable() {
			this.isScrolling = true;
		},
		onSearch() {
			this.pagination.currentPage = 1;
			let searchUserInfo = {
				registername: this.model.registername,
				companyname: this.model.companyname,
				type: this.model.type,
				authority: this.model.authority,
			};
			this.tableData = this.queryTableData(searchUserInfo);
		},
		paginationChanged(page) {
			this.pagination.currentPage = page;
		},
		queryTableData(value) {
			let userList = this.originalTableData;
			let result = userList
				.filter((el) => {
					if (value.registername) {
						return el.registername
							.toLowerCase()
							.includes(value.registername.toLowerCase());
					} else {
						return true;
					}
				})
				.filter((el) => {
					if (value.companyname) {
						return el.companyname
							.toLowerCase()
							.includes(value.companyname.toLowerCase());
					} else {
						return true;
					}
				})
				.filter((el) => {
					if (value.type) {
						return el.type.toString() === value.type.toString();
					} else {
						return true;
					}
				})
				.filter((el) => {
					if (value.authority) {
						return el.authority.toString() === value.authority.toString();
					} else {
						return true;
					}
				});
			return result;
		},
	},
	async mounted() {
		this.isMobile = this.checkMobile();
	},
};
</script>
<style scoped>
.table-header > thead {
	font-size: 1.25em !important;
}

label {
	margin-left: 10px;
	font-family: NotoSansJPBold;
}

#user-master {
	font-family: NotoSansJPBold;
}

.select-label {
	margin-right: 10px;
}

.col__custom {
	display: grid;
	place-content: center;
}
.scoll_hint_img {
	width: 30%;
	height: 30%;
	left: 35%;
	top: 30%;
	margin: 0 auto;
	position: absolute;
}
.not_display {
	display: none !important;
}
</style>
